/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FaCoins } from "react-icons/fa";
function Header() {
  return (
    <header>
      <nav className='navbar navbar-expand-lg fixed-top'>
        <div className='container'>
          <a className='navbar-brand' href='/'>
            <img src='/Logo-2.svg' width={120} alt='' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0 text-center m-auto'>
              <li className='nav-item'>
                <Link className='nav-link active' aria-current='page' to='/'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/encyclopedia'>
                  Encyclopedia
                </Link>
              </li>
              {/* <li className='nav-item'>
                <a className='nav-link' href='#coming-soon'>
                  Staking
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#coming-soon'>
                  Governance
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  VIP Tier
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Convert
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  History
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Refer
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  More
                </a>
              </li> */}
            </ul>
            <a rel='noopener noreferrer'>
              {" "}
              <button className='nav-btn'>
                <FaCoins /> Buy Monstr
              </button>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
