import React from "react";
import { Tabs,Tab } from "react-bootstrap";
import Booster from "./Booster";
import REL from "./REL";
function Hero() {
  return (
    <div className="container-fluid section-padding page2-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 encyclo">
            <h1 data-aos="fade-down" data-aos-delay='100'>ENCYCLOPEDIA</h1>
            <hr />
            <p data-aos="fade-right" data-aos-delay='300'>
              Monsters to battle against players around the world <br className="line-break"/> to earn
              exciting rewards
            </p>
            <hr className="dash-border" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="Monstr"
              id="uncontrolled-tab-example"
              className="mb-3"
              data-aos="zoom-in" data-aos-delay='100'
            >
              <Tab eventKey="Monstr" title="Monstr" className="tabss">
                  <REL/>
              </Tab>
              <Tab eventKey="Booster" title="Booster">
                  <Booster/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
