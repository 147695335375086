import React from 'react'
import Hero from './encyclopedia/Hero'
function Encyclopedia() {
  return (
    <>
    <Hero/>
    </>
  )
}

export default Encyclopedia