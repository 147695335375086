/** @format */

import React from "react";
// import { BiMailSend } from "react-icons/bi";
import { BsMedium } from "react-icons/bs";
import {
  FaReddit,
  FaDiscord,
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaTelegram,
  FaFacebookF,
} from "react-icons/fa";
function Footer() {
  return (
    <footer>
      <div className='container-fluid  footer-bg'>
        <div className='row'>
          <div className='col-md-4 footer-left-para'>
            <img
              src={"/Logo-2.svg"}
              alt=''
              className='footer-img'
            />
            <p className='mt-5'>
              Monverse is a first ever NFT game based on a lifestyle of Monsters
              allowing players to control their monsters and fight their
              battles.
            </p>
            {/* <div className="d-flex">
            <BiMailSend color="#EE8405" size={25} />
            <p className="mx-2">hello@monvaer.com</p>
          </div> */}
          </div>
          <div className='col-md-4 footer-list col-6 m-auto text-center'>
            <p>MONVERSE</p>
            <ul className='list-unstyled'>
              <li>
                <a
                  href='https://monverse.gitbook.io/monverse-p2e-game-bsc/'
                  target='_blank'
                  rel='noreferrer'>
                  Document
                </a>
              </li>
              <li>
                <a target='_blank' rel='noreferrer' href='/whitepaper.pdf'>
                  Whitepaper
                </a>
              </li>
              <li>
                <a
                  href='https://github.com/solidproof/projects/blob/main/Monverse/SmartContract_Audit_Solidproof_Monverse.pdf'
                  rel='noreferrer'
                  target='_blank'>
                  Audits
                </a>
              </li>
              <li>
                <a
                  href='https://app.monverse.fun'
                  rel='noreferrer'
                  target='_blank'>
                  Marketplace
                </a>
              </li>
              <li>
                <a
                  href='http://stake.monverse.fun/'
                  rel='noreferrer'
                  target='_blank'>
                  Staking
                </a>
              </li>
              <li>
                <a href='/gov' target='_blank'>
                  Governance
                </a>
              </li>
            </ul>
          </div>

          <div className='col-md-4 footer-list'>
            <p>SIGN UP FOR NEWSLETTER</p>
            <div className='input'>
              <input type='email' name='' id='' placeholder='Your email' />
              <button>Subscribe</button>
            </div>

            <h1>Our Community</h1>
            <div>
            <a href='https://medium.com/@monversegame' target='_blank' rel='noopener noreferrer'>
                <BsMedium color='#A5ABB3' className='mx-2' size={23} />
              </a>
              <a href='https://twitter.com/monversegame' target='_blank' rel='noopener noreferrer'>
                <FaTwitter color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://t.me/monversegame' target='_blank' rel='noopener noreferrer'>
                <FaTelegram color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://discord.gg/6te3zadbPe' target='_blank' rel='noopener noreferrer'>
                <FaDiscord color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://www.reddit.com/user/Monversegame' target='_blank' rel='noopener noreferrer'>
                <FaReddit color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://www.instagram.com/monversegame/' target='_blank' rel='noopener noreferrer'>
                <FaInstagram color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://github.com/Monversegame' target='_blank' rel='noopener noreferrer'>
                <FaGithub color='#A5ABB3' size={23} className='mx-2' />
              </a>
              <a href='https://www.facebook.com/Monversegame/' target='_blank' rel='noopener noreferrer'>
                <FaFacebookF color='#A5ABB3' size={23} className='mx-2' />
              </a>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <hr style={{ background: "#2C3A4B" }} />
          </div>
          <div className='col-md-6'>
            <p className='footer-right-text'>
              Monverse 2024 © All right reserved
            </p>
          </div>
          <div className='col-md-6 text-end'>
            <img src='assets/app.svg' alt='' />
            <img src='assets/app1.svg' alt='' className='mx-3' />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;