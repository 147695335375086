/** @format */

import React from "react";
import { AiFillStar } from "react-icons/ai";
function MarketPlace() {
  return (
    <div className='container-fluid section-padding marketplace-bg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h1 className='market-hero-text' data-aos='fade-down'>
              MARKETPLACE
            </h1>
            <hr
              style={{ border: "2px solid #EE8405", width: "130px" }}
              data-aos='fade-right'
            />
          </div>
          <div className='col-md-6 text-end market-btns'>
            <button data-aos='zoom-in'>
              <img src={"/phantom.png"} width={40} alt='' className='mx-2' /> Connected
            </button>
            <button data-aos='zoom-in'>
              <img src='/sol.svg' alt='' className='mx-2 ' width={28} /> Solana
              Chain
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-3 col-6'>
            <div
              className='card market-top-cards'
              data-aos='fade-up'
              data-aos-delay='100'>
              <div className='row'>
                <div className='col-md-3 m-auto text-center'>
                  <img src={"/bat.svg"} alt='' />
                </div>
                <div className='col-md-9'>
                  <div className='card-body'>
                    <h1>106</h1>
                    <p>Traders</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div
              className='card market-top-cards'
              data-aos='fade-up'
              data-aos-delay='300'>
              <div className='row'>
                <div className='col-md-3 m-auto text-center'>
                  <img src={"/diamond.svg"} alt='' />
                </div>
                <div className='col-md-9'>
                  <div className='card-body'>
                    <h1>$354,654</h1>
                    <p>Total Volume</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div
              className='card market-top-cards'
              data-aos='fade-up'
              data-aos-delay='500'>
              <div className='row'>
                <div className='col-md-3 m-auto text-center'>
                  <img src={"/sales.svg"} alt='' />
                </div>
                <div className='col-md-9'>
                  <div className='card-body'>
                    <h1>$6,549</h1>
                    <p>Biggest Sale</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div
              className='card market-top-cards'
              data-aos='fade-up'
              data-aos-delay='700'>
              <div className='row'>
                <div className='col-md-3 m-auto text-center'>
                  <img src={"/sold.svg"} alt='' />
                </div>
                <div className='col-md-9'>
                  <div className='card-body'>
                    <h1>136</h1>
                    <p>Items Sold</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr
            style={{ border: "1px dashed #6540CF", width: "98%" }}
            className='mt-4 text-center m-auto'
          />
        </div>

        <div className='row mt-5'>
          <div className='col-md-3 siderbar'>
            <div
              className='market-sidebar '
              data-aos='fade-right'
              data-aos-delay='300'>
              <h1>Filter</h1>
              <hr />
              <h2>Genus</h2>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                  checked
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  GS1
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  GS1
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  GS1
                </label>
              </div>
              <hr />
              <h2>Types</h2>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  <img src='sol.svg' alt='' className='mx-3' width={20} />
                  Solana
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  <img src={"/eth.svg"} alt='' width={25} className='mx-3' />
                  Etherium
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  <img src='poly.svg' alt='' className='mx-3' width={20} />
                  Polygone
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  <img src={"/mon.svg"} alt='' className='mx-3' />
                  Moonbeam
                </label>
              </div>

              <hr />
              <h2>Rarities</h2>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  Common
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  Uncommon
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  Rare
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultValue
                  id='flexCheckDefault'
                />
                <label
                  className='form-check-label mx-2'
                  htmlFor='flexCheckDefault'>
                  Legendary
                </label>
              </div>
            </div>
          </div>

          <div className='col-md-9'>
            <div className='row'>
              <div className='col-md-12'>
                <div
                  className='market-top-row'
                  data-aos='fade-down'
                  data-aos-delay='100'>
                  <div className='dropdown'>
                    <button
                      className='btn btn-secondary dropdown-toggle'
                      type='button'
                      id='dropdownMenuButton1'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'>
                      Recent Sold
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div
                  className='card market-card'
                  data-aos='fade-up'
                  data-aos-delay='100'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='card market-card'
                  data-aos='fade-up'
                  data-aos-delay='300'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='card market-card'
                  data-aos='fade-up'
                  data-aos-delay='500'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='card market-card '
                  data-aos='fade-up'
                  data-aos-delay='100'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='card market-card'
                  data-aos='fade-up'
                  data-aos-delay='300'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='card market-card'
                  data-aos='fade-up'
                  data-aos-delay='500'>
                  <div className='card-body'>
                    <img
                      src='sol.svg'
                      alt=''
                      className='right-img'
                      width={25}
                    />
                    <div>
                      <p>Level 1</p>
                      <span>Sold</span>
                    </div>
                    <img src={"/12.svg"} alt='' className='market-hero-img' />
                    <div className='star'>
                      <AiFillStar color='#EE8405' />
                      <h1 className='mx-2'>Earth</h1>
                    </div>
                    <h3>
                      1500 <img src={"/star.svg"} alt='' />
                    </h3>
                    <h6>G25 | Common</h6>
                    <hr />

                    <div className='row'>
                      <div className='col-md-8 col-6'>
                        <ul className='list-unstyled market-list-items'>
                          <li>
                            <img src='bat.svg' alt='' /> &nbsp; Battle Power
                          </li>
                          <li>
                            <img src='market2.svg' alt='' />
                            &nbsp; &nbsp; Perfection
                          </li>
                          <li>
                            <img src='market3.svg' alt='' />
                            &nbsp;&nbsp; Rewards factor
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-4 text-end col-6'>
                        <h5>150</h5>
                        <h5>45%</h5>
                        <h5>2.3</h5>
                      </div>
                    </div>
                    <div className='m-auto text-center'>
                      <button>0x5fd02dd8ae095</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketPlace;
