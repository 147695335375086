/** @format */

import React, { useState } from "react";
import { AiFillStar, AiOutlineEye } from "react-icons/ai";

import { Modal } from "react-bootstrap";
function Monverse() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modal, setModal] = useState({
    imgUrl: null,
  });

  return (
    <div className='container section-padding'>
      <div className='row main'>
        <div className='col-md-12'>
          <h1 data-aos='fade-up' data-aos-delay='100'>
            Types
          </h1>
          <p data-aos='fade-down' data-aos-delay='300'>
            Each element type is strong against 2 other types. Its primary
            weakness is the element <br /> type you must use to support catch a
            monster of that type.
          </p>
          <div className='row'>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-down'
                data-aos-delay='100'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Earth</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-down'
                data-aos-delay='300'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Electric</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-down'
                data-aos-delay='500'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Fire</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-down'
                data-aos-delay='700'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Wind</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-up'
                data-aos-delay='100'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Dark</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-up'
                data-aos-delay='300'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Light</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-up'
                data-aos-delay='500'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Dark</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div
                className='card tab-card'
                data-aos='fade-up'
                data-aos-delay='700'>
                <div className='card-body'>
                  <div className='d-flex align-items-center'>
                    <div className='div'></div>
                    <h1>Light</h1>
                  </div>
                  <img src={"/Line28.svg"} alt='' className='res-img-line' />
                  <ul className='list-unstyled'>
                    <li>
                      <img src={"/Vector2.svg"} alt='' className='mx-2' />{" "}
                      Electric Light
                    </li>
                    <li>
                      <img src={"/vector4.svg"} alt='' className='mx-2' /> Wind
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-5 main'>
        <h1 data-aos='fade-down' data-aos-delay='100'>
          Mascot Monstr
        </h1>
        <p data-aos='fade-right' data-aos-delay='300'>
          Each element type is strong against 2 other types. Its primary
          weakness is the element <br /> type you must use to support catch a
          monster of that type.
        </p>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='100'
            onClick={() => {
              setModal({
                imgUrl: "/12.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#021</p>
              <h5>Averton</h5>
              <img src={"/12.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='300'
            onClick={() => {
              setModal({
                imgUrl: "/11.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#022</p>
              <h5>Averton</h5>
              <img src={"/11.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='500'
            onClick={() => {
              setModal({
                imgUrl: "/13.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#023</p>
              <h5>Averton</h5>
              <img src={"/13.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='100'
            onClick={() => {
              setModal({
                imgUrl: "/14.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#024</p>
              <h5>Averton</h5>
              <img src={"/14.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='300'
            onClick={() => {
              setModal({
                imgUrl: "/15.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#025</p>
              <h5>Averton</h5>
              <img src={"/15.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='500'
            onClick={() => {
              setModal({
                imgUrl: "/16.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#026</p>
              <h5>Averton</h5>
              <img src={"/16.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-5 main'>
        <h1 data-aos='fade-down' data-aos-delay='100'>
          Genesis Monstr
        </h1>
        <p data-aos='fade-right' data-aos-delay='100'>
          Each element type is strong against 2 other types. Its primary
          weakness is the element <br /> type you must use to support catch a
          monster of that type.
        </p>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='100'
            onClick={() => {
              setModal({
                imgUrl: "/17.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#001</p>
              <h5>Averton</h5>
              <img src={"/17.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='300'
            onClick={() => {
              setModal({
                imgUrl: "/18.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#002</p>
              <h5>Averton</h5>
              <img src={"/18.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-down'
            data-aos-delay='500'
            onClick={() => {
              setModal({
                imgUrl: "/19.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#003</p>
              <h5>Averton</h5>
              <img src={"/19.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='100'
            onClick={() => {
              setModal({
                imgUrl: "/14.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#004</p>
              <h5>Averton</h5>
              <img src={"/14.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='300'
            onClick={() => {
              setModal({
                imgUrl: "/15.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#005</p>
              <h5>Averton</h5>
              <img src={"/15.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div
            className='card tab1-card'
            data-aos='fade-up'
            data-aos-delay='500'
            onClick={() => {
              setModal({
                imgUrl: "/11.svg",
              });
              handleShow();
            }}>
            <div className='card-body'>
              <p style={{ float: "right" }} className='tab-right-text'>
                ARI<span className='mx-2'> Mascot</span>
              </p>
              <p>#006</p>
              <h5>Averton</h5>
              <img src={"/11.svg"} alt='' className='tab-hero-img' /> <br />
              <div className='star'>
                <AiFillStar color='#EE8405' /> <h1 className='mx-2'>Earth</h1>
              </div>
              <div className='tab-icons'>
                <img src='sol.svg' alt='' width={28} />
                <img src='poly.svg' alt='' width={28} />
                <img src={"/eth.svg"} alt='' width={28} />
                <img src={"/mon.svg"} width={28} alt='' />
                <button className='mx-2'>
                  <AiOutlineEye /> view
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <Modal show={show} fullscreen={true} onHide={handleClose}>
            <Modal.Header closeButton closeVariant='white'></Modal.Header>
            <Modal.Body>
              <div className='row modal-bg m-auto'>
                <div className='col-md-5  '>
                  <div className='card modal-card'>
                    <div className='card-body'>
                      <p style={{ float: "right" }} className='tab-right-text'>
                        ARI<span className='mx-2'> Mascot</span>
                      </p>
                      <p>#021</p>
                      <h5>Averton</h5>
                      <img src={modal.imgUrl} alt='' className='tab-hero-img' />
                      <br />
                      <div className='star'>
                        <AiFillStar color='#EE8405' />{" "}
                        <h1 className='mx-2'>Earth</h1>
                      </div>
                      <div className='tab-icons'>
                        <img src='sol.svg' alt='' width={28} />
                        <img src='/poly.svg' alt='' width={28} />
                        <img src={"/eth.svg"} alt='' width={28} />
                        <img src={"/mon.svg"} width={28} alt='' />
                      </div>
                      <p>35 cought</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-1'>
                  <div
                    className='d-flex justify-content-center'
                    style={{ height: "450px" }}>
                    <div className='vr' style={{ background: "#A5ABB3" }}></div>
                  </div>
                </div>
                <div className='col-md-6 modal-rt'>
                  <p>
                    A lively and unruly prince born from a thunderbolt. Be
                    cautious, as he enjoys shooting for amusement.
                  </p>
                  <div className='md-box'>
                    <h1>Avg. Seen Time</h1>
                    <h2>Genesis</h2>
                  </div>
                  <div className='md-box mt-3'>
                    <h1>Avg. Seen Time</h1>
                    <h2>Genesis</h2>
                  </div>
                  <h1 className='model-text'>Battle Stats Range</h1>
                  <div
                    style={{ borderBottom: "1px solid #A5ABB3" }}
                    className='mt-1'>
                    <h3>130-540</h3>
                    <h4>HP</h4>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #A5ABB3" }}
                    className='mt-1'>
                    <h3>147-180</h3>
                    <h4>Attack</h4>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #A5ABB3" }}
                    className='mt-1'>
                    <h3>190-202</h3>
                    <h4>Defence</h4>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #A5ABB3" }}
                    className='mt-1'>
                    <h3>44-56</h3>
                    <h4>Special Attack</h4>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #A5ABB3" }}
                    className='mt-1'>
                    <h3>130-540</h3>
                    <h4>Speed</h4>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Monverse;
