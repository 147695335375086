import React from 'react'
import MarketPlace from '../components/element/MarketPlace'

function Home() {
  return (
    <div>
        <MarketPlace/>
    </div>
  )
}

export default Home