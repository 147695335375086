import React from 'react'

function Booster() {
    // const booster =[
    //     {imgUrl:'assets/booster.svg', text: 'Attack +5%'}
    // ]
  return (
    <div className='container section-padding'>
        <div className="row booster-main">
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Common</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Uncommon</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Rare</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Epic</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Common</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Uncommon</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Rare</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card booster-card">
                        <div className="card-body">
                            <img src={"/booster.svg"} alt="" />
                            <div className="btn-box">
                            <button>Epic</button>
                            </div>
                            <p>Attack +5%</p>
                        </div>
                    </div>
                </div>
                
        </div>
    </div>
  )
}

export default Booster